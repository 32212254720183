import React from 'react';
import { graphql } from 'gatsby';
import BackgroundEvent from 'components/Layouts/BackgroundEvent';
import ResolutionChecker from 'components/ResolutionChecker';

const BackgroundEventsPageTemplate = (props) => {
  const {
    allTimelineEvents: {
      edges: [{ node }],
    },
  } = props.data;

  const historicalBackgroundEvent = node.BackgroundEvents.find(
    (event) =>
      event.EventId === props.pageContext.eventId &&
      event.Title === props.pageContext.title
  );

  const startYear = +historicalBackgroundEvent.StartDisplayYear;
  const endYear = +historicalBackgroundEvent.EndDisplayYear;

  const relatedEvents = node.Events.filter((event) => {
    return (
      +event?.StartDisplayYear >= startYear &&
      +event?.StartDisplayYear <= endYear
    );
  });

  return (
    <ResolutionChecker>
      <BackgroundEvent
        event={historicalBackgroundEvent}
        relatedEvents={relatedEvents}
        location={props?.location}
      />
    </ResolutionChecker>
  );
};

export default BackgroundEventsPageTemplate;

export const query = graphql`
  query {
    allTimelineEvents {
      edges {
        node {
          BackgroundEvents {
            BAnQDescription
            EndDisplayYear
            EndDisplay
            EventId
            JulianDayEnd
            JulianDayStart
            StartDisplay
            StartDisplayYear
            Title
          }
          Events {
            BAnQ_LastUpdateDate
            BAnQ_ThumbnailNoticePath
            Body
            DescriptionFirme
            FeatureImagePath
            EventId
            ImageCredit
            LinkTarget
            JulianDayStart
            LinkText
            Priority
            StartDisplay
            ThumbnailPath
            StartDisplayYear
            Title
            Year
          }
        }
      }
    }
  }
`;
